import { useTranslation } from 'react-i18next';
import * as Styled from './archived-list.style';
import { useEffect, useMemo, useRef, useState } from 'react';
import { StyledButton } from './open-archived-button/open-archived-button.container';
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  DownloadOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { useParams } from 'react-router';
import { history, URL_CONSTANTS } from 'utils/history';
import { Input, Tooltip } from 'antd';
import { useArchivedFields } from './use-archived-fields.hook';
import { EmptyStateIcon } from './empty-state.icon';
import { UnarchiveIcon } from '../unarchive.icon';
import { FloatingBulkActions } from 'app/common/floating-bulk-actions/floating-bulk-actions.component';
import { DownloadAndDeleteModals } from './download-and-delete-modals/download-and-delete-modals.component';
import 'pages/fields/list/allFields.less';
import { useArchiving } from '../archiving.provider';
import { ArchivedFieldsList } from './side-list/archived-fields-list.component';
import Map, { MapRef, PropertyFieldsLayer } from '@cw-elements/map';
import { ZoomControls } from 'components/mapbox/zoomControls';
import { MyPropertyMyLocationButtons } from 'components/mapbox/propertyLocationButtons';
import { AllGeoJSON, bbox as turfBbox, center as turfCenter } from '@turf/turf';
import { Neutral } from 'app/theme';

interface PropsWithRBAC {
  rbacPermissions?: {
    'fields:delete': boolean;
  };
}

export function ArchivedFieldsContainer({ rbacPermissions }: PropsWithRBAC) {
  const mapRef = useRef<MapRef>(null);
  const { id: orgId, farmId } = useParams();
  const { t } = useTranslation();

  const { openConfirmUnarchive, fetchArchivedFields } = useArchiving();
  const {
    fields,
    loadingRegionNames,
    loading,
    filteredFields,
    sortDirection,
    query,
    onSearch,
    toggleSortDir
  } = useArchivedFields();

  const [showFloatingBulkActions, setShowFloatingBulkActions] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [downloadOpen, setDownloadOpen] = useState(false);

  const [selectedFieldsIds, setSelectedFieldsIds] = useState<string[]>([]);

  const [fieldsGeometry, center, bbox] = useMemo(() => {
    try {
      if (!loading && fields.length > 0) {
        const featCollection = {
          type: 'FeatureCollection',
          features: fields.map(
            (field) =>
              ({
                type: 'Feature',
                properties: {
                  id: field.id,
                  name: field.name
                },
                geometry: field.geometry
              } as const)
          )
        } as AllGeoJSON;
        const centerLngLat = turfCenter(featCollection);
        const [minLng, minLat, maxLng, maxLat] = turfBbox(featCollection);
        mapRef.current?.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat]
          ],
          { padding: 200, duration: 3000 }
        );

        return [
          featCollection,
          centerLngLat.geometry.coordinates,
          [minLng, minLat, maxLng, maxLat]
        ];
      } else {
        return [null, null, null];
      }
    } catch {
      return [null, null, null];
    }
  }, [fields, loading]);

  const handleToggleAll = () => {
    const isAllSelected = selectedFieldsIds.length === fields.length;
    if (mapRef.current?.isStyleLoaded()) {
      fields.forEach(({ id }) => {
        mapRef.current?.setFeatureState(
          { source: 'geojson-source', id },
          { selected: !isAllSelected }
        );
      });
    }

    const selectedFieldsIdsAfterToggle = isAllSelected ? [] : fields.map(({ id }) => id);
    setSelectedFieldsIds(selectedFieldsIdsAfterToggle);
    setShowFloatingBulkActions(selectedFieldsIdsAfterToggle.length > 0);
  };

  const handleToggleOne = (id: string) => {
    if (mapRef.current?.isStyleLoaded()) {
      const { selected } = mapRef.current?.getFeatureState({ source: 'geojson-source', id });
      mapRef.current?.setFeatureState({ source: 'geojson-source', id }, { selected: !selected });
    }

    const newSelectedFields = selectedFieldsIds.includes(id)
      ? selectedFieldsIds.filter((_id) => _id !== id)
      : [...selectedFieldsIds, id];

    setShowFloatingBulkActions(newSelectedFields.length > 0);
    setSelectedFieldsIds(newSelectedFields);
  };

  const getSearchInputIcon = () => {
    if (loadingRegionNames) {
      return (
        <Tooltip title={t('archiving.archived_list.loading_regions_tooltip')}>
          <LoadingOutlined
            aria-label={t('archiving.archived_list.loading_regions_tooltip') as string}
          />
        </Tooltip>
      );
    }
    return <SearchOutlined style={{ color: Neutral[60] }} />;
  };

  const renderList = () => {
    if (loading) {
      return <LoadingOutlined style={{ marginTop: 24 }} />;
    }
    if (fields.length === 0) {
      return (
        <Styled.EmptyStateContainer>
          <EmptyStateIcon />
          <span>{t('archiving.archived_list.empty_state')}</span>
        </Styled.EmptyStateContainer>
      );
    }
    return (
      <ArchivedFieldsList
        fields={filteredFields}
        sortDirection={sortDirection}
        selectedFieldsIds={selectedFieldsIds}
        isAllSelected={selectedFieldsIds.length === fields.length}
        onToggleSortDirection={toggleSortDir}
        onSelectAllFields={handleToggleAll}
        onSelectField={handleToggleOne}
      />
    );
  };

  useEffect(() => {
    if (!showFloatingBulkActions) {
      // Wait footer animation
      const id = setTimeout(() => {
        setSelectedFieldsIds([]);
      }, 300);

      return () => clearTimeout(id);
    }
  }, [showFloatingBulkActions]);

  useEffect(() => {
    // Clear old fields from the selected array
    const fieldsIds = fields.map((field) => field.id);
    setSelectedFieldsIds((selectedFieldsIds) =>
      selectedFieldsIds.filter((id) => fieldsIds.includes(id))
    );
  }, [fields]);

  return (
    <Styled.Container>
      <Styled.FieldListContainer>
        <Styled.Header>
          <StyledButton
            onClick={() => {
              history.push(
                URL_CONSTANTS.ALL_FIELDS({ orgId: orgId as string, farmId: farmId as string })
              );
            }}>
            <ArrowLeftOutlined />
            {t('Back')}
          </StyledButton>
          <Styled.Help>
            <QuestionCircleOutlined />
            {t('Help')}
          </Styled.Help>
        </Styled.Header>
        <Input
          size='large'
          placeholder={t('archiving.archived_list.search_placeholder') as string}
          value={query}
          prefix={getSearchInputIcon()}
          onChange={onSearch}
        />
        {renderList()}
      </Styled.FieldListContainer>
      <Styled.MapContainer>
        {center && fieldsGeometry && (
          <>
            <Map
              ref={mapRef}
              mapboxAccessToken={process.env.REACT_APP_MAPBOX_API_KEY}
              interactiveLayerIds={['geojson-source']}
              initialViewState={{
                longitude: center[0],
                latitude: center[1]
              }}>
              <PropertyFieldsLayer
                id='geojson-source'
                data={fieldsGeometry as GeoJSON.FeatureCollection}
                onClickField={handleToggleOne}
                styles={{
                  default: {
                    fillColor: Neutral[90],
                    fillOpacity: 0.3,
                    borderColor: Neutral[20]
                  },
                  selected: {
                    fillOpacity: 0.3
                  }
                }}
                overrides={{ line: { 'line-dasharray': [2, 2] } }}
              />
            </Map>
            <ZoomControls
              zoomInClick={() => {
                mapRef.current?.zoomIn();
              }}
              zoomOutClick={() => {
                mapRef.current?.zoomOut();
              }}
            />
            {bbox && (
              <MyPropertyMyLocationButtons
                handleLocateProperty={() => {
                  try {
                    const [minLng, minLat, maxLng, maxLat] = bbox;

                    mapRef.current?.fitBounds(
                      [
                        [minLng, minLat],
                        [maxLng, maxLat]
                      ],
                      { padding: 200, duration: 3000 }
                    );
                  } catch {
                    console.info('Invalid geometry passed to PropertyFieldsLayer');
                  }
                }}
              />
            )}
          </>
        )}

        <FloatingBulkActions
          title={t('archiving.archived_list.footer_actions.title')}
          count={selectedFieldsIds.length}
          actions={[
            {
              label: t('Download'),
              icon: <DownloadOutlined />,
              onClick: () => {
                setDownloadOpen(true);
              }
            },
            {
              label: t('archiving.archived_list.footer_actions.unarchive_button'),
              tooltip: t('archiving.archived_list.footer_actions.disabled_tooltip'),
              icon: <UnarchiveIcon />,
              disabled: selectedFieldsIds.length !== 1,
              onClick: () => {
                openConfirmUnarchive(selectedFieldsIds[0]);
              }
            },
            {
              label: t('global.footer_actions.delete_field', {
                count: selectedFieldsIds.length || 1
              }),
              icon: <DeleteOutlined />,
              danger: true,
              disabled: !rbacPermissions?.['fields:delete'],
              onClick: () => {
                setDeleteOpen(true);
              }
            }
          ]}
          open={showFloatingBulkActions}
          onClose={() => setShowFloatingBulkActions(false)}
        />
      </Styled.MapContainer>
      <DownloadAndDeleteModals
        deleteOpen={deleteOpen}
        setDeleteOpen={setDeleteOpen}
        downloadOpen={downloadOpen}
        setDownloadOpen={setDownloadOpen}
        selectedFields={fields.filter((field) => selectedFieldsIds.includes(field.id))}
        setSelectedFields={setSelectedFieldsIds}
        fetchArchivedFields={fetchArchivedFields}
      />
    </Styled.Container>
  );
}
