import { ReactNode, useEffect, useRef, useState } from 'react';
import * as Styled from './floating-bulk-actions.style';
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import { CloseOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

interface Action {
  label: string;
  onClick: () => void;
  tooltip?: string | null;
  icon?: ReactNode;
  danger?: boolean;
  disabled?: boolean;
}

interface FloatingBulkActionsProps {
  open: boolean;
  onClose: () => void;
  title: string;
  count: number | null;
  actions: Action[];
}

export function FloatingBulkActions({ open, title, actions, onClose, ...props }: FloatingBulkActionsProps) {
  const [count, setCount] = useState(props.count);
  const { t } = useTranslation();
  const ref = useRef(null);

  useEffect(() => {
    if (props.count) setCount(props.count);
  }, [props.count]);

  return (
    <CSSTransition
      nodeRef={ref}
      in={open}
      timeout={300}
      classNames='floating-bulk-actions'
      unmountOnExit
      mountOnEnter>
      <Styled.Container ref={ref}>
        <Styled.Header>
          <span className='title'>{title}</span>
          <div>
            {Boolean(count) && (
              <span>{t('global.footer_actions.field_selected', { count: count as number })}</span>
            )}
            <span
              className='close'
              role='button'
              aria-label='close footer actions'
              onClick={onClose}>
              <CloseOutlined />
            </span>
          </div>
        </Styled.Header>
        <Styled.InnerContainer>
          {actions.map((action, idx) => {
            return (
              <Tooltip
                overlayClassName='white-bg-tooltip'
                title={action.tooltip}
                key={`${idx}-${action.label}`}>
                <Styled.Button
                  $disabled={action.disabled}
                  $danger={action.danger}
                  onClick={!action.disabled ? action.onClick : undefined}>
                  {action.icon && <span className='icon'>{action.icon}</span>}
                  {action.label}
                </Styled.Button>
              </Tooltip>
            );
          })}
        </Styled.InnerContainer>
      </Styled.Container>
    </CSSTransition>
  );
}
