import { useTranslation } from 'react-i18next';

import { iff } from '../../utils/iff';
import { translationWrapper } from '../../utils/commonMethods';

export const Loader = (props) => {
  const { t } = translationWrapper(useTranslation());
  return (
    <div style={props.style}>
      {iff(props.mask, <div className='mask' />)}
      <div className='loader-wrapper'>
        {iff(
          props.showMessage,
          <div className='loader-text'>
            {props.message ? props.message : t('global.loading_text')}
          </div>
        )}
        <div className='loader' style={iff(!props.showMessage, { right: '-18px' })} />
      </div>
    </div>
  );
};