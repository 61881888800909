import { FontIcon } from '../../common/iconComponent';
import { iff } from '../../../utils/iff';
import './sortIcons.less';

export const SortIcons = ({ orderBy }) => {
  return (
    <div className='sort-icon-up-down'>
      <span className='up-span'>
        <FontIcon
          name='up-arrow-without-bg'
          size={7}
          color={iff(orderBy === 'asc', '#363948', '#696F88')}
          style={{ fontWeight: 'bold' }}
        />
      </span>
      <span className='down-span'>
        <FontIcon
          name='down-arrow-without-bg'
          size={7}
          color={iff(orderBy === 'desc', '#363948', '#696F88')}
          style={{ fontWeight: 'bold' }}
        />
      </span>
    </div>
  );
};

export const SortIconsV2 = ({ orderBy }) => {
  return (
    <div className='sort-icon-container'>
      <div className='up'>
        <FontIcon
          name='up-arrow-without-bg'
          size={7}
          color={iff(['asc', 'ascend'].includes(orderBy), '#363948', '#696F88')}
          style={{ fontWeight: 'bold' }}
        />
      </div>
      <div className='down'>
        <FontIcon
          name='down-arrow-without-bg'
          size={7}
          color={iff(['desc', 'descend'].includes(orderBy), '#363948', '#696F88')}
          style={{ fontWeight: 'bold' }}
        />
      </div>
    </div>
  );
};