import { CSSProperties, memo } from 'react';
import styled from 'styled-components';

import { iff } from '../../utils/iff';
import { split } from 'lodash';

const StyledIcon = styled.span<{ $size?: number | string }>`
  &::before {
    color: ${(props) => props.color || '#14151C'};
    font-size: ${(props) =>
      iff(
        props.$size !== undefined && props.$size !== null && props.$size !== '',
        `${props.$size}px`,
        'inherit'
      )};
  }
`;

interface IconComponentProps {
  size?: number | string;
  name?: string;
  color?: string
  style?: CSSProperties,
  extraClassName?: string;
}

const IconComponent = ({ size, name, color, style = {}, extraClassName = '' }: IconComponentProps) => {
  return (
    <>
      {iff(
        name !== null && name !== undefined && name !== '' && split(name, ' ').length === 1,
        <StyledIcon
          style={{ ...style }}
          className={`${`icon-${name}`}${iff(
            extraClassName !== null && extraClassName !== undefined && extraClassName !== '',
            ` icon-${extraClassName}`,
            ''
          )}`}
          color={color}
          $size={size}
        />,
        <StyledIcon
          style={{ ...style }}
          className={`icon-phenology-1 ${iff(
            extraClassName !== null && extraClassName !== undefined && extraClassName !== '',
            `icon-${extraClassName}`,
            ''
          )}`}
          color={color}
          $size={size}
        />
      )}
    </>
  );
};

export const FontIcon = memo(IconComponent);
