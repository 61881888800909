import { notification } from 'antd';
import styled from 'styled-components';
import { Button } from 'syngenta-digital-cropwise-react-ui-kit';
import { ExclamationCircleOutlined, CheckCircleOutlined, CloseOutlined } from '@ant-design/icons';

import { iff } from '../../utils/iff';

const StyledMessage = styled.div`
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.0075em;
  color: #14151c;
`;

const StyledDescription = styled.div`
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.0075em;
  color: #14151c;
`;

const CustomNotification = styled.div`
  width: 425px;
  padding: 10px;
  height: 164px;
  background-color: white;
  border-radius: 5px;
  border-left: 4px solid #0092e4;
  position: fixed;
  right: 20px;
  top: 150px;
  z-index: 100;
  .title {
    display: flex;
    h2 {
      font-family: Noto Sans, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.0075em;
      color: #14151c;
      width: 320px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .icon {
    font-size: 20px;
    width: 50px;
  }
  .close {
    font-size: 20px;
    cursor: pointer;
  }
  .description {
    margin-left: 50px;
    height: 75px;
    p {
      font-family: Noto Sans, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.0075em;
      color: #14151c;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
    .cancelText,
    .okText {
      background-color: white;
      color: #14151c;
      border: 0px;
      border-radius: 5px;
      padding: 5px 10px;
      margin: 0px 10px;
      cursor: pointer;
    }
  }
`;

const colors = {
  success: '#19A04B',
  failed: '#CF3537',
  info: '#0092E4',
  default: '#CF3537'
};

export const showNotification = (type, message, description, { duration = 4.5, key = '', actionButtons = [], className = '' } = {}, extraContent = null) => {
  let btn = actionButtons.map((btn) => (
    <Button className={className} key={btn.name} type={btn.type || 'text'} size="small" onClick={btn.onClick}>
      {btn.name}
    </Button>
  ));
  if (btn.length) {
    btn = <div className='success-notification-action-button-container'>{btn}</div>
  }
  notification.open({
    duration,
    key,
    message: <StyledMessage>{message}</StyledMessage>,
    description: (
      <>
        {description ? <StyledDescription>{description}</StyledDescription> : null}
        {extraContent}
      </>
    ),
    style: {
      borderLeft: `2px solid ${colors[type]}`,
      borderRadius: '4px',
      marginTop: '32px'
    },
    icon: iff(
      type === 'success',
      <CheckCircleOutlined style={{ color: colors[type] }} />,
      <ExclamationCircleOutlined style={{ color: colors[type] }} />
    ),
    btn
  });
};

export const hideNotificationByKey = (key) => notification.close(key);

export const ShowNotificationWithButtons = ({ content }) => {
  const { type, message, description, okText, cancelText, onOk, onCancel, onClose } = content;
  return (
    <CustomNotification style={{ borderLeft: `4px solid ${colors[type]}` }}>
      <div className='title'>
        <ExclamationCircleOutlined className='icon' style={{ color: colors[type] }} />
        <h2>{message}</h2>
        <CloseOutlined onClick={onClose} className='close' />
      </div>
      <div className='description'>
        <p>{description}</p>
      </div>
      <div className='buttons'>
        <button className='okText' onClick={onOk}>
          {okText}
        </button>
        <button
          style={
            type === 'info'
              ? { backgroundColor: '#19A04B', color: 'white' }
              : { backgroundColor: colors[type], color: 'white' }
          }
          className='cancelText'
          onClick={onCancel}>
          {cancelText}
        </button>
      </div>
    </CustomNotification>
  );
};