import { HTMLAttributes } from 'react';

export function UnarchiveIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.3625 0.6625L13.405 1.9225C13.6225 2.1775 13.75 2.515 13.75 2.875V12.25C13.75 13.075 13.075 13.75 12.25 13.75H1.75C0.925 13.75 0.25 13.075 0.25 12.25V2.875C0.25 2.515 0.3775 2.1775 0.595 1.9225L1.63 0.6625C1.84 0.4075 2.1475 0.25 2.5 0.25H11.5C11.8525 0.25 12.16 0.4075 12.3625 0.6625ZM11.32 1.75H2.68L2.065 2.5H11.9425L11.32 1.75ZM1.75 12.25V4H12.25V12.25H1.75ZM5.9125 8.5H4L7 5.5L10 8.5H8.0875V10.75H5.9125V8.5Z'
        fill='#696F88'
      />
    </svg>
  );
}
