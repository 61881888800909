/* eslint-disable react/no-unescaped-entities */
import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Select, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Logo from '../../../public/logo.png';
import { fetchUserProfile, fetchUserProfileError, openLocationModal } from '../../../redux/actions/user';
import { editUserDetails } from '../../../redux/actions/userAccessManagement';
import { AppConstants } from '../../../utils/app.constants';
import { translationWrapper } from '../../../utils/commonMethods';
import { getUserLocationDetails } from '../../../utils/ipApi';
import './retroLocationModal.style.less';

const StyledModal = styled(Modal)`
  .ant-modal-header {
    padding: 16px 24px;
    color: #000;
    background: #fff;
    border-bottom: 3px solid #f74141;
    border-radius: 4px 4px 0 0;
  }
  .ant-modal,
  .ant-modal-content {
    height: 100vh;
    width: 100vw;
    background-color: #fff;
  }
  .ant-modal-body {
    height: 100vh;
    position: relative;
  }
`;

const RetroLocationModalWrapper = (props) => {
  const { t } = translationWrapper(useTranslation());
  const [userCountry, setUserCountry] = useState();
  const isModalOpen = props.isVisible;
  const user = props.user;
  const userName = user.first_name ? user.first_name : user.name;
  const { isEditLoading, hasEditSucceded, closeLocationModal } = props;
  const fetchLocation = () => {
    getUserLocationDetails().then((data) => {
      const countryCode = data?.country_code || '';
      const supportedCountry = AppConstants.SUPPORTED_COUNTRIES.filter(
        (country) => country.value.toLowerCase() === countryCode.toLowerCase()
      );
      if (supportedCountry) {
        setUserCountry(supportedCountry[0]?.value || '');
      }
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const payload = {
      role: user.role,
      ssn: user.ssn,
      phone: user.phone,
      name: user.name,
      locale: user.locale,
      clock_format: user.clock_format,
      date_format: user.date_format,
      tutorial_complete: user.tutorial_complete,
      job_title: user.job_title,
      country_code: userCountry
    };
    props.editUserDetails(payload, user.id);
  };

  useEffect(() => {
    fetchLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEditLoading && hasEditSucceded && closeLocationModal) {
      closeLocationModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isEditLoading, props.hasEditSucceded]);

  return (
    <div>
      <StyledModal
        visible={isModalOpen}
        destroyOnClose={true}
        centered={true}
        closable={false}
        width={'100%'}
        footer={null}
        mask={false}>
        <div
          className='inner-container'
          style={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            height: '100%'
          }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} align='middle'>
            <Col className='gutter-row' span={6}>
              <div style={{ padding: '8px', textAlign: 'center' }} />
            </Col>
            <Col className='gutter-row' span={12}>
              <div className='logoContainer'>
                <img src={Logo} alt={t('global.image_alt.home_work')} />
              </div>
              <div id={'name-container'} data-testid={'name-container'}>
                <h1
                  key={props.name}
                  id={'user-name'}
                  data-testid={'user-name'}
                  className='name-container'
                  >
                  {`${t('global.retro_location_modal.hello')} ${userName} !`}
                </h1>
              </div>
              <div id={'message-container'} data-testid={'message-container'} className='message-container'>
                <p
                  className='message-container-first'
                  >
                  {t('global.retro_location_modal.before_checking_out_your_farm')}
                </p>
                <p
                  className='message-container-second'
                  >
                  {t('global.retro_location_modal.dont_worry')}
                </p>
              </div>
              <div
                id={'location-label'}
                className='accounts-lable'
                data-testid='label'
                style={{
                  position: 'relative',
                  color: 'black',
                  marginLeft: '5px',
                  marginTop: '40px',
                  fontSize: '12px'
                }}>
                {t('global.retro_location_modal.location')}
                <Tooltip
                  title={
                    <div className='tooltip-wrap'>
                      {t('global.retro_location_modal.tooltip_message')}
                    </div>
                  }
                  overlayStyle={{
                    whiteSpace: 'pre'
                  }}
                  style={{
                    padding: '8px 8px !important'
                  }}
                  trigger={'click'}
                  placement={'topRight'}>
                  <InfoCircleOutlined
                    style={{
                      padding: '5px',
                      position: 'absolute',
                      right: 0
                    }}
                  />
                </Tooltip>
              </div>
              <div
                style={{ padding: '8px', textAlign: 'center' }}
                data-testid={'countryDropdownContainer'}>
                <Select
                  id={'selectedCountryDropdown'}
                  data-testid={'selectedCountryDropdown'}
                  value={userCountry}
                  onSelect={setUserCountry}
                  showSearch={true}
                  optionFilterProp={'children'}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  bordered={false}
                  style={{
                    backgroundColor: '#f3f4f4',
                    borderRadius: '5px',
                    color: '#14151c',
                    fontSize: '14px',
                    lineHeight: '40px !important',
                    textAlign: 'left',
                    width: '100%',
                    height: '40px',
                    marginBottom: '16px'
                  }}>
                  {AppConstants?.SUPPORTED_COUNTRIES?.map((country) => {
                    return (
                      <Select.Option
                        id={`country-dropdown-${country.value}`}
                        key={country.value}
                        value={country.value}>
                        {country.name}
                      </Select.Option>
                    );
                  })}
                </Select>
                <div
                  id={'country-btn-div'}
                  data-testid={'country-btn-div'}
                  style={{ marginTop: '40px' }}>
                  <Button
                    id={'submit-country-btn'}
                    data-testid={'submit-country-btn'}
                    onClick={onSubmit}>
                    {t('common.button.continue')}
                  </Button>
                </div>
              </div>
            </Col>
            <Col className='gutter-row' span={6}>
              <div style={{ padding: '8px', textAlign: 'center' }} />
            </Col>
          </Row>
        </div>
      </StyledModal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userProfile,
    isVisible: state.user.isLocationModalOpen,
    isEditLoading: state.userAccessManagement.loading,
    hasEditSucceded: state.userAccessManagement.editUserDetailsSuccess
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchUserDetails: (isCache) => dispatch(fetchUserProfile(isCache)),
  setUserUpdateError: (error) => dispatch(fetchUserProfileError(error)),
  closeLocationModal: () => dispatch(openLocationModal(false)),
  editUserDetails: (payload, id) => dispatch(editUserDetails(payload, id))
});

export const RetroLocationModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(RetroLocationModalWrapper);
